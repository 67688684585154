.Collapsible {
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 4px;
  }
  
  .Collapsible-title {
    margin: 0;
    padding-bottom: 10px;
    font-size: 18px;
    color: #333;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-bottom:2px solid;
    background-color: #000000 !important;
  }
  
  .Collapsible-content {
    padding-top: 20px;
  }
  .Collapsible-heading_divider{
    margin-bottom: 20px;
    border-bottom:2px solid;
    background-color: #000000 !important;

  }

  .group-select-container {
    .group-select-label,
    .group-select {
        margin-left: 20px;
        font-size: 14px;
    }

    .max-group-text {
        margin-left: 20px;
        font-size: 12px;
        font-weight: bold;
        margin-top: 10px;
    }
}

.text_size{
  font-size: 18px;
}

.margin_bottom{
  margin-bottom: 20px ;
}
  .document-preview {
    max-width: 100%;
    margin: auto;
  
    &__title {
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    &__divider {
      margin-bottom: 20px;
      border-bottom:5px solid;
      background-color: #000000 !important;
    }
  
    &__form {
      .form-control {
        margin-bottom: 10px; 
      }
  
      .button-group {
        display: flex;
        gap: 10px; 
        align-items: center;
        justify-content: flex-start; 
      }
    }
  
    .button {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-weight: bold;
      &--retrieve {
        background-color: limegreen;
        color: white;
      }
      &--clear {
        background-color: #f8f9fa;
        color: #212529;
        border: 1px solid #ced4da;
      }
    }
  
    .form-static-text {
      padding: 8px;
      background-color: #e9ecef;
      border-radius: 4px;
    }
  
    .form-label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }
  }

.createDocument{
  margin-right: 5px ;
  font-weight: bold;
  font-weight: 18px;

}
.select{
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
 
$table-cell-padding: 0.5rem;
$input-border-color: #ced4da;
$input-border-radius: 0.25rem;
$button-background-save: #28a745; 
$button-background-generate: #007bff; 




.document-preview {
  label {
    display: block; 
    margin: 0.5rem 0;
  }
  
  input[type="text"] {
    // width: 100%;
    padding: $table-cell-padding;
    margin-bottom: 1rem; 
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  
    th {
      text-align: left;
      padding: $table-cell-padding;
    }
  
    td {
      padding: $table-cell-padding;
  
      input[type="text"] {
        width: 100%;
        padding: $table-cell-padding;
        border: 1px solid $input-border-color;
        border-radius: $input-border-radius;
  
        &:focus {
          outline: none;
          border-color: darken($input-border-color, 10%);
        }
      }
    }
  }
}



.button {
  padding: $table-cell-padding 1rem;
  border: none;
  border-radius: $input-border-radius;
  font-weight: bold;
  cursor: pointer;
  margin-right: 0.5rem; 
  color: #fff;

  &--save {
    background-color: $button-background-save;
  }

  &--generate {
    background-color: $button-background-generate;
  }
}


.variable--algin{
  display: flex;
  margin-left: 30%;
  font-weight: bold;
}
.help--algin{
  margin-right: 10px;
}
.dtid-text{
  margin-left: 7rem !important;
}