.dataTables_wrapper .dataTables_filter {
  float: left !important;
}

.option-viewed {
  background-color: lightgreen;
}

.option-default {
  background-color: lightgoldenrodyellow;
}

.readonlyInput {
  width: 100%;
  color: gray;
  background-color: white !important;
}

.hoverUnderline {
  cursor: pointer;
  text-decoration: none;
}

.hoverUnderline:hover {
  text-decoration: underline;
}

.linkButton {
  text-decoration: underline;
  color: #1a5a96;
  padding: 0;
  border: none;
  background-color: transparent;
}

.linkButton:hover {
  color: blue;
}

.removeAdminInput {
  color: gray;
  min-width: 270px;
}

/**
* override for React's close button
*/
.btn-close {
  opacity: 1;
  background-color: transparent;
  border: none;
  width: 18px;
  height: 18px;
  position: relative;
}

.btn-close::before {
  content: 'X';
  font-weight: bold;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
}

.btn-close:focus {
  outline: none;
  box-shadow: none;
}

readonly {
  background-color: lightgray !important;
}
